import React from 'react'
import Helmet from 'react-helmet'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Layout from '../components/layout'

class SubscribePage extends React.Component {
    state = {
        fname: null,
        lname: null,
        email: null,
        message: null,
    }
    
    _handleChange = e => {
        this.setState({
          [`${e.target.name}`]: e.target.value,
        })
    }
    
    _handleSubmit = e => {
        e.preventDefault();
        addToMailchimp(this.state.email, {fname: this.state.fname, lname: this.state.lname})
        .then(({msg, result}) => {
          if (result !== 'success') {
            throw msg;
          }
          this.setState({message: msg});
        })
        .catch(err => {
          this.setState({message: err});
        });
    }
    
    render () {
        return (
            <Layout>
                <Helmet title="Subscribe | Fluxible" />
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className="cell medium-6 medium-offset-3">
                            <h1 className="page-header">Subscribe for news &amp; announcements</h1>
                            <p>Join our spam-free mailing list to stay in the know about Fluxible. We promise not to share your information with anyone, and you’ll be able to remove yourself from the list whenever you like.</p>
                            <form onSubmit={this._handleSubmit}>
                                <label>Your first name (required)
                                <input type="text" name="fname" onChange={this._handleChange} />
                                </label>

                                <label>Your last name (required)
                                <input type="text" name="lname" onChange={this._handleChange} />
                                </label>

                                <label>Your email (required)
                                <input type="text" name="email" onChange={this._handleChange} />
                                </label>

                                <button type="submit" className="button action-button">Subscribe</button>
                            
                                {this.state.message && <div className="callout warning"><p dangerouslySetInnerHTML={{__html: this.state.message}} /></div>}
                            </form>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default SubscribePage
